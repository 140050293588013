import { kebabCase } from 'lodash';

const TAG_IDS = {
  404: {
    BUTTON_RETURN_HOME: '404-return-home-button',
  },
  ACT_TEST_SCORES: {
    ACT_TEST_SCORE_ABOUT_DIALOG: {
      CLOSE: 'dialog-close-button-act-test-score-about',
    },
    ACT_TEST_SCORES_DIALOG: {
      CLOSE: 'dialog-close-button-act-test-scores',
    },
    ACT_TEST_SCORES_YOUR_SCORES_DIALOG: {
      CLOSE: 'act-test-scores-your-scores-dialog-close-button',
    },
    EMPTY_STATE: {
      CLOSE_MODAL_BTN_READY_TO_REGISTER: 'dialog-close-modal-btn-ready-to-register',
      CLOSE_MODAL_BTN_WHERES_MY_SCORE: 'dialog-close-modal-btn-wheres-my-score',
      CLOSE_MODAL_READY_TO_REGISTER: 'dialog-close-modal-ready-to-register',
      CLOSE_MODAL_WHERES_MY_SCORE: 'dialog-close-modal-wheres-my-score',
      OPEN_MODAL_READY_TO_REGISTER: 'dialog-open-modal-ready-to-register',
    },
    SEND_ACT_SCORE_REPORT_BTN: 'send-score-report-btn',
    WHAT_IS_SUPERSCORE: {
      LEARNMORE_BTN: 'learnmore-btn-what-is-superscore',
      MODAL_CLOSE: 'dialog-close-button-what-is-superscore',
    },
  },
  ARTICLE: {
    BUTTON_SIGN_IN: 'article-button-signin',
  },
  ARTICLES: {
    ARTICLE_CARD: (articleId: string): string => `article-card-${articleId}`,
    BUTTON_HEART: (articleId: string): string => `articles-save-button-${articleId}`,
    BUTTON_PLAY_VIDEO: (videoId: string): string => `articles-play-video-button-${videoId}`,
  },
  CAREER: {
    ANCHOR_DETAILS: 'career-details',
    ANCHOR_EDUCATION: 'career-education',
    ANCHOR_OVERVIEW: 'career-overview',
    BUTTON_CHANGE_PAY_INFORMATION: 'career-change-pay-information-button',
    BUTTON_DETAILS_TAB: 'career-details-tab-button',
    BUTTON_EDUCATION_TAB: 'career-education-tab-button',
    BUTTON_EXPAND_AKA_LIST: 'career-expand-aka-list-button',
    BUTTON_KNOWLEDGE_VIEW_MORE: 'career-button-knowledge-view-more',
    BUTTON_OVERVIEW_TAB: 'career-overview-tab-button',
    BUTTON_RESPONSIBILITIES_VIEW_ALL: 'career-button-responsibilities-view-all',
    BUTTON_SAVE_CAREER: 'career-button-save-career',
    BUTTON_SIMILAR_CAREERS_VIEW_MORE: 'career-button-similar-careers-view-more',
    BUTTON_TOOLS_USED_VIEW_MORE: 'career-button-tools-used-view-more',
    CAROUSEL_SIMILAR_CAREERS: 'career-carousel-similar-careers',
  },
  CAREERS: {
    BUTTON_BROWSE_MILIRARY_CAREERS: 'careers-browse-military-careers-button',
    BUTTON_CLUSTERS_DIALOG_BACK: 'careers-clusters-dialog-back-button',
    BUTTON_EXPLORE_TAB: 'careers-explore-tab-button',
    BUTTON_MATCHED_TAB: 'careers-matched-tab-button',
    BUTTON_SAVED_TAB: 'careers-saved-tab-button',
    BUTTON_SEARCH_CAREERS_DIALOG_BACK: 'careers-search-dialog-back-button',
    BUTTON_VIEW_ALL_CLUSTERS: 'careers-view-all-clusters-button',
    TEXT_FIELD_SEARCH_CAREERS: 'careers-text-field-search-careers',
    TEXT_FIELD_SEARCH_CAREERS_CLEAR: 'careers-text-field-search-careers-clear',
    TEXT_FIELD_SEARCH_CAREERS_DIALOG: 'careers-text-field-dialog-search-careers',
  },
  COLLEGE_PROFILE: {
    ANCHOR_ADMISSIONS: 'college-profile-admissions',
    ANCHOR_AREAS_OF_STUDY: 'college-profile-areas-of-study',
    ANCHOR_COSTS: 'college-profile-costs',
    ANCHOR_MAJORS: 'college-profile-majors',
    ANCHOR_STUDENT_BODY: 'college-profile-student-body',
    AOS: {
      BUTTON_APPLY_NOW: 'college-profile-aos-apply-now-button',
      BUTTON_GET_MORE_INFO: 'college-profile-aos-get-more-info-button',
      BUTTON_SCHEDULE_VISIT: 'college-profile-aos-schedule-visit-button',
    },
    BUTTON_ADMISSIONS_TAB: 'college-profile-tabs-admissions-button',
    BUTTON_AREA_OF_STUDY: 'college-profile-area-of-study-button',
    BUTTON_AREAS_OF_STUDY_TAB: 'college-profile-tabs-areas-of-study-button',
    BUTTON_CLOSE_AREA_OF_STUDY: 'college-profile-close-area-of-study-button',
    BUTTON_CLOSE_REACT_NATIVE: 'college-profile-close-react-native-button',
    BUTTON_COSTS_TAB: 'college-profile-tabs-costs-button',
    BUTTON_MAJORS_TAB: 'college-profile-tabs-major-button',
    BUTTON_STUDENT_BODY_TAB: 'college-profile-tabs-student-body-button',
  },
  COLLEGES: {
    BUTTON_BROWSE_ALL: 'colleges-browse-all-button',
    BUTTON_BROWSE_BACK: 'colleges-browse-back-button',
    BUTTON_CLOSE_FILTERS: 'colleges-close-filters-button',
    BUTTON_EDIT_PREFERENCES: 'colleges-edit-preferences-button',
    BUTTON_EXPLORE_TAB: 'colleges-explore-tab-button',
    BUTTON_FILTER_APPLY: 'colleges-filter-apply-button',
    BUTTON_FILTER_PRESET_2_YEAR: 'colleges-filter-preset-2-year-button',
    BUTTON_FILTER_PRESET_4_YEAR: 'colleges-filter-preset-4-year-button',
    BUTTON_FILTER_PRESET_CHEAPEST_IN_STATE: 'colleges-filter-preset-cheapest-in-state-button',
    BUTTON_FILTER_PRESET_FINANCIAL_AID: 'colleges-filter-preset-financial-aid-button',
    BUTTON_FILTER_PRESET_HAS_MY_MAJOR: 'colleges-filter-preset-has-my-major-button',
    BUTTON_FILTER_PRESET_HIGHEST_ACCEPTANCE_RATE: 'colleges-filter-preset-highest-acceptance-rate-button',
    BUTTON_FILTER_PRESET_LEAST_DEBT: 'colleges-filter-preset-least-debt-button',
    BUTTON_FILTER_PRESET_MOST_STUDENTS: 'colleges-filter-preset-most-students-button',
    BUTTON_FILTER_PRESET_PUBLIC: 'colleges-filter-preset-public-button',
    BUTTON_FILTER_RESET: 'colleges-filter-reset-button',
    BUTTON_FILTERS: 'colleges-filters-button',
    BUTTON_HEART: (fice: string): string => `colleges-save-button-${fice}`,
    BUTTON_INTERESTED_TAB: 'colleges-interested-tab-button',
    BUTTON_MATCHED_TAB: 'colleges-matched-tab-button',
    BUTTON_SAVED_TAB: 'colleges-saved-tab-button',
    BUTTON_SEE_ALL_MATCHES: 'colleges-see-all-matches-button',
    COLLEGE_CARD: (fice: string): string => `college-card-${fice}`,
    COLLEGE_SEARCH: 'college-search',
    FILTERS: {
      ACCEPTANCE_RATES: 'colleges-filters-acceptance-rates',
      CAMPUS_POPULATIONS: 'colleges-filters-campus-populations',
      COLLEGE_TYPES: 'colleges-filters-college-types',
      COSTS: 'colleges-filters-costs',
      GPAS: 'colleges-filters-gpas',
      MAJORS: 'colleges-filters-majors',
      REGIONS: 'colleges-filters-regions',
      RELIGIOUS_AFFILIATIONS: 'colleges-filters-religious-affiliations',
      STATES: 'colleges-filters-states',
      YEAR_LEVELS: 'colleges-filters-year-levels',
    },
  },
  COMPLETE_PROFILE: {
    ACCEPT_TERMS: {
      BUTTON_ACCEPT: 'complete-profile-terms-accept-button',
      BUTTON_BACK: 'complete-profile-terms-back-button',
    },
    GETTING_STARTED: {
      BUTTON_START: 'complete-profile-getting-started-button',
    },
    RECRUITMENT: {
      CONFIRM: 'complete-profile-recruitment-confirm-button',
      RADIO_NO: 'complete-profile-recruitment-no-option',
      RADIO_YES: 'complete-profile-recruitment-yes-option',
    },
  },
  DASHBOARD: {
    BUTTON_ADD_COLLEGE: 'dashboard-add-college-button',
    BUTTON_JOURNEY_DIALOG_CLOSE: 'dashboard-journey-dialog-close-button',
    BUTTON_JOURNEY_DIALOG_CONFIRM: 'dashboard-journey-dialog-confirm-button',
    BUTTON_VIEW_7TH_TO_10TH_JOURNEY: 'dashboard-view-7th-to-10th-journey-button',
    BUTTON_VIEW_COLLEGES: 'dashboard-view-colleges-button',
    BUTTON_VIEW_JOURNEY_INFO: 'dashboard-view-journey-info-button',
    BUTTON_VIEW_MORE_COLLEGES: 'dashboard-view-more-colleges-button',
    BUTTON_VIEW_MORE_SCHOLARSHIPS: 'dashboard-view-more-scholarships-button',
    BUTTON_VIEW_SCHOLARSHIPS: 'dashboard-view-scholarships-button',
    GOALS_SECTION: {
      BUTTON_ACCEPT_UPDATED: 'dashboard-accept-updated-data-button',
      BUTTON_EDIT_FIELD: (sectionName: string) => `dashboard-edit-${sectionName}-button`,
      BUTTON_OPEN_EDIT_PAGE: 'dashboard-open-edit-page-button',
    },
  },
  DIALOG: {
    BUTTON_CLOSE: 'dialog-button-close',
    ICON_BUTTON_CLOSE: 'dialog-icon-button-close',
  },
  EDIT_GOALS: {
    BUTTON_BACK_FROM_SURVEY: `edit-goals-return-survey-dialog-button`,
  },
  EDUCATOR_CONNECT: {
    INLINE: {
      CANCEL_BUTTON: 'inline-educator-connect-cancel-button',
      CANCEL_REMOVAL_BUTTON: 'inline-educator-connect-cancel-removal-button',
      CONFIRM_REMOVAL_BUTTON: 'inline-educator-connect-confirm-removal-button',
      EDIT_BUTTON: 'inline-educator-connect-edit-button',
      PIN_TEXTFIELD: 'inline-educator-connect-pin-textfield',
      REMOVE_BUTTON: 'inline-educator-connect-remove-button',
      UPDATE_BUTTON: 'inline-educator-connect-update-button',
    },
    MODAL: {
      ADD_BUTTON: 'educator-connect-modal-add-button',
      BACK_BUTTON: 'educator-connect-modal-back-button',
      EDUCATOR_INFO_GRID: 'educator-connect-modal-educator-info-grid',
      FINISH_BUTTON: 'educator-connect-modal-finish-button',
      PIN_TEXTFIELD: 'educator-connect-modal-pin-textfield',
    },
    PROFILE: {
      EDIT_FIELD_BUTTON: 'edit-field-button',
      REMOVE_EDUCATOR_BUTTON: 'remove-educator-button',
    },
  },
  EXPLORE: {
    CARD_MY_CAREERS: 'explore-card-my-careers',
    CARD_MY_COLLEGES: 'explore-card-my-colleges',
    CARD_MY_LIBRARY: 'explore-card-my-library',
    CARD_MY_MAJORS: 'explore-card-my-majors',
    CARD_MY_SCHOLARSHIPS: 'explore-card-my-scholarships',
  },
  FEEDBACK: {
    BUTTON_CLOSE: 'feedback-button-close',
    BUTTON_SUBMIT: 'feedback-button-submit',
    EMAIL: 'feedback-email',
    TEXTAREA: 'feedback-textarea',
  },
  FORGOT_PASSWORD: {
    BUTTON_CANCEL: 'forgot-password-cancel-button',
    BUTTON_SUBMIT: 'forgot-password-submit-button',
    INPUT_EMAIL: 'forgot-password-email-input',
  },
  HEADER: {
    BUTTON_BACK: 'header-back-button',
    BUTTON_SIGNIN: 'header-signin-button',
  },
  HEADER_LEFT: {
    BUTTON_NAVIGATION_MENU_CLOSE: 'header-left-navigation-close-button',
    BUTTON_NAVIGATION_MENU_OPEN: 'header-left-navigation-open-button',
  },
  HEADER_RIGHT: {
    BUTTON_FEEDBACK: 'header-right-feedback-button',
    BUTTON_MENU_OPEN_CLOSE: 'header-right-menu-open-close-button',
    MENU: {
      ACTION_MANAGE_ACCOUNT: 'header-right-menu-manage-account-action',
      ACTION_SIGNOUT: 'header-right-menu-signout-action',
    },
  },
  INTEREST_INVENTORY: {
    CLOSE: 'interest-inventory-close-button',
    FINISH_QUIZ: 'interest-inventory-finish-quiz',
    LATER: 'interest-inventory-later-button',
    RETAKE_QUIZ: 'interest-inventory-retake-quiz',
    START: 'interest-inventory-start-button',
    TAKE_QUIZ: 'interest-inventory-take-quiz',
    VIEW_CAREER_MATCHES: 'interest-inventory-view-career-matches',
    VIEW_MAJOR_MATCHES: 'interest-inventory-view-major-matches',
    VIEW_MATCHES: 'interest-inventory-view-matches',
  },
  INTERESTED_COLLEGES: {
    INFO_DIALOG: {
      CLOSE_BUTTON: 'interested-colleges-info-dialog-close-button',
      OPEN_DIALOG_BUTTON: 'open-interested-colleges-info-dialog-button',
    },
  },
  ITEM_AREAS: {
    BUTTON_SHOW_MORE: 'careers-show-more',
  },
  JOURNEY: {
    JOURNEY_SELECTOR_BUTTON: 'journey-selector-button',
    JOURNEY_SELECTOR_POPPER: 'journey-selector-popper',
    JOURNEY_TRANSITION_GOT_IT_BUTTON: 'journey-transition_got_it_button',
    JOURNEY_VIEW_CURRENT_BUTTON: 'journey-view-current-button',
    MILESTONE_STATUS_SELECTOR_BUTTON: 'journey-milestone-status-selector-button',
    SELECTOR_BUTTON: 'journey-category-selector-button',
    SELECTOR_POPPER: 'journey-category-selector-popper',
  },
  LIBRARY: {
    BUTTON_BACK_TO_LIBRARY: 'library-button-back-to-library',
    BUTTON_SAVED: 'library-button-saved',
    BUTTON_SIGNIN: 'library-button-signin',
    CATEGORY_NAV_ITEM: (categoryTitle: string) => `library-category-nav-${kebabCase(categoryTitle)}`,
    CATEGORY_TAB: 'library-category-tab',
    ERROR_MODAL: {
      CLOSE_BUTTON: 'library-error-modal-close-button',
    },
    SECTION: (id: string) => `library-section-${id}`,
    TOPIC_SEARCH: {
      BUTTON_MOBILE_SIGNIN: 'library-link-mobile-signin-topic-search',
      BUTTON_TOPIC_SEARCH: 'library-button-topic-search',
      INPUT_ADORNMENT_CLEAR_TOPIC_SEARCH: 'library-input-adornment-clear-topic-search',
      INPUT_ADORNMENT_TOPIC_SEARCH: 'library-input-adornment-topic-search',
      INPUT_TOPIC_SEARCH: 'library-input-topic-search',
      LIST_ITEM_CATEGORY: (title: string): string => `library-list-item-category=${kebabCase(title)}`,
    },
  },
  MAJOR: {
    ANCHOR_CAREERS: 'major-careers',
    ANCHOR_COLLEGES: 'major-colleges',
    ANCHOR_OVERVIEW: 'major-overview',
    BUTTON_ALL_COLLEGES_VIEW_MORE: 'major-button-all-colleges-view-more',
    BUTTON_CAREERS_TAB: 'major-careers-tab-button',
    BUTTON_CLOSE_ACTION_SHEET: 'major-button-close-action-sheet',
    BUTTON_COLLEGES_TAB: 'major-colleges-tab-button',
    BUTTON_LEARNING_POINTS_VIEW_ALL: 'major-button-learning-points-view-all',
    BUTTON_MATCHED_COLLEGES_VIEW_MORE: 'major-button-matched-colleges-view-more',
    BUTTON_OVERVIEW_TAB: 'major-overview-tab-button',
    BUTTON_RELATED_CAREERS_VIEW_MORE: 'major-button-related-careers-view-more',
    BUTTON_RELATED_MAJORS_VIEW_MORE: 'major-button-related-majors-view-more',
    BUTTON_SAVE_MAJOR: 'major-button-save-major',
    BUTTON_SAVE_MAJOR_MENU: 'major-save-major-menu',
    BUTTON_SAVE_MAJOR_MENU_ITEM: 'major-save-major-menu-item',
    BUTTON_SAVE_MAJOR_PROFILE_RADIO: 'major-save-major-profile-radio',
    BUTTON_SAVED_COLLEGES_VIEW_MORE: 'major-button-saved-colleges-view-more',
    BUTTON_STUDENT_ABILITIES_VIEW_ALL: 'major-button-student-abilities-view-all',
    BUTTON_VIEW_ALL_COLLEGES: 'major-button-view-all-colleges',
  },
  MAJOR_GROUP: {
    BUTTON_SEE_ALL: 'major-group-see-all-button',
  },
  MAJORS: {
    BUTTON_EXPLORE_TAB: 'majors-explore-tab-button',
    BUTTON_MATCHED_TAB: 'majors-matched-tab-button',
    BUTTON_SAVED_TAB: 'majors-saved-tab-button',
    BUTTON_SEARCH_MAJORS_DIALOG_BACK: 'majors-search-back-button',
    BUTTON_VIEW_ALL_MAJORS: 'majors-view-all-button',
    BUTTON_VIEW_ALL_MAJORS_DIALOG_BACK: 'majors-view-all-dialog-back-button',
    TEXT_FIELD_SEARCH_MAJORS: 'majors-text-field-search-majors',
    TEXT_FIELD_SEARCH_MAJORS_CLEAR: 'majors-text-field-search-majors-clear',
  },
  MANAGE_ACCOUNT: {
    ACCOUNT: {
      ACTION_CHANGE_PASSWORD: 'manage-account-account-change-password-action',
      ACTION_SIGNOUT: 'manage-account-account-signout-action',
      ACTION_TAB: 'manage-account-account-tab-action',
      ACTION_UPDATE_EMAIL: 'manage-account-account-update-email-action',
      DIALOG_CHANGE_PASSWORD: {
        ACTION_CANCEL: 'manage-account-account-tab-change-password-dialog-cancel-action',
        ACTION_CLOSE: 'manage-account-account-tab-change-password-dialog-close-action',
        ACTION_UPDATE: 'manage-account-account-tab-change-password-dialog-update-action',
        INPUT_CONFIRM_PASSWORD: 'manage-account-account-tab-change-password-dialog-confirm-password-input',
        INPUT_CURRENT_PASSWORD: 'manage-account-account-tab-change-password-dialog-current-password-input',
        INPUT_PASSWORD: 'manage-account-account-tab-change-password-dialog-password-input',
      },
      DIALOG_CHANGE_PASSWORD_LOGOUT: {
        ACTION_CANCEL: 'manage-account-account-tab-change-password-logout-dialog-cancel-action',
        ACTION_CLOSE: 'manage-account-account-tab-change-password-logout-dialog-close-action',
        ACTION_SIGNOUT: 'manage-account-account-tab-change-password-logout-dialog-signout-action',
      },
      DIALOG_UPDATE_EMAIL: {
        ACTION_CANCEL: 'manage-account-account-tab-update-email-dialog-cancel-action',
        ACTION_CLOSE: 'manage-account-account-tab-update-email-dialog-close-action',
        ACTION_SAVE: 'manage-account-account-tab-update-email-dialog-save-action',
        INPUT_CONFIRM_EMAIL: 'manage-account-account-tab-update-email-dialog-confirm-email-input',
        INPUT_CURRENT_EMAIL: 'manage-account-account-tab-update-email-dialog-current-email-input',
        INPUT_EMAIL: 'manage-account-account-tab-update-email-dialog-email-input',
      },
      FCM_TOKEN_MODAL: 'manage-account-account-tab-fcm-token-modal',
      FCM_TOKEN_MODAL_CLOSE_BUTTON: 'manage-account-account-tab-fcm-token-modal-close-button',
      FCM_TOKEN_MODAL_CLOSE_DIALOG: 'manage-account-account-tab-fcm-token-modal-close-dialog',
      FCM_TOKEN_MODAL_COPY_BUTTON: 'manage-account-account-tab-fcm-token-modal-copy-button',
      FCM_TOKEN_MODAL_SUBSCRIBE_QA_TOPIC: 'manage-account-account-tab-fcm-token-modal-subscribe-qa-button',
      VERSION_NUMBER: 'manage-account-account-tab-version-number-press-action',
    },
    DELETE_ACCOUNT: {
      DIALOG: {
        CLOSE_BUTTON: 'manage-account-account-tab-delete-account-dialog-cancel-action',
        DELETE_BUTTON: 'manage-account-account-tab-delete-account-dialog-delete-action',
        OPEN_EMAIL: 'manage-account-account-tab-delete-account-dialog-open-email-action',
        PRIVACY_BUTTON: 'manage-account-account-tab-delete-account-dialog-privacy-action',
        STAY_BUTTON: 'manage-account-account-tab-delete-account-dialog-stay-action',
      },
    },
    LEGAL: {
      ACTION_TAB: 'manage-account-legal-tab-action',
      LINK_ACT_PRIVACY: 'manage-account-legal-act-privacy-link',
      LINK_ACT_TERMS: 'manage-account-legal-act-terms-link',
      LINK_ENCOURAGE_PRIVACY: 'manage-account-legal-encourage-privacy-link',
      LINK_ENCOURAGE_TERMS: 'manage-account-legal-encourage-terms-link',
    },
    PERSONAL_INFO: {
      ACTION_TAB: 'manage-account-personal-info-tab-action',
      BUTTON_EDIT: (settingName: string) => `manage-account-personal-info-edit-button-${kebabCase(settingName)}`,
      DROPDOWN_FIELD: (settingName: string) => `manage-account-personal-info-edit-dropdown-${kebabCase(settingName)}`,
    },
    RECRUITING_PREFERENCE: {
      ACTION_IM_IN: 'manage-account-recruiting-preference-im-in-action',
      ACTION_TAB: 'manage-account-recruiting-preference-tab-action',
      DIALOG_OPT_OUT: {
        ACTION_CLOSE: 'manage-account-recruiting-preference-opt-out-dialog-close-action',
        ACTION_OPT_OUT: 'manage-account-recruiting-preference-opt-out-dialog-opt-out-action',
        ACTION_STAY_IN: 'manage-account-recruiting-preference-opt-out-dialog-stay-in-action',
      },
      DIALOG_OPT_OUT_RESULT: {
        ACTION_BACK_TO_PROFILE: 'manage-account-recruiting-preference-opt-out-result-dialog-back-to-profile-action',
        ACTION_CLOSE: 'manage-account-recruiting-preference-opt-out-result-dialog-close-action',
      },
    },
  },
  MATCHED_COLLEGES: {
    COMPLETE_PROFILE_BUTTON: 'complete-profile-button-on-empty-state',
    INFO_DIALOG: {
      CLOSE_BUTTON: 'matched-colleges-info-dialog-close-button',
      OPEN_DIALOG_BUTTON: 'open-matched-colleges-info-dialog-button',
    },
  },
  MILESTONE: {
    MILESTONE_CHECKLIST_ITEM: 'milestone-checklist-item',
    NOTE: {
      BACK_BUTTON: 'milestone-note-back-dialog-button',
      CLOSE_DIALOG: 'milestone-note-close-dialog-button',
      EXIT_BUTTON: 'milestone-note-exit-dialog-button',
      HELPER_TEXT: 'milestone-note-helper-text',
      TEXTAREA: 'milestone-note-textarea',
      TRY_AGAIN_BUTTON: 'milestone-note-try-again-button',
    },
  },
  MILITARY_CAREER: {
    BUTTON_DETAILS_TAB: 'military-career-details-tab-button',
    BUTTON_OVERVIEW_TAB: 'military-career-overview-tab-button',
    BUTTON_RESPONSIBILITIES_VIEW_ALL: 'military-career-button-responsibilities-view-all',
    BUTTON_SIMILAR_MILITARY_CAREERS_VIEW_MORE: 'military-career-button-similar-military-careers-view-more',
    CAROUSEL_RELATED_CIVILIAN_CAREERS: 'military-career-carousel-related-civilian-careers',
    CAROUSEL_SIMILAR_MILITARY_CAREERS: 'military-career-carousel-similar-military-careers',
  },
  MILITARY_CAREERS: {
    BUTTON_SAVE_CAREER: 'military-careers-save-button',
    BUTTON_VIEW_ALL_MILITARY: (id: string) => `military-careers-button-view-all-${id}`,
    CAROUSEL_MILITARY: (id: string) => `military-careers-carousel-${id}`,
    DIALOG_SEARCH_RESULTS: 'military-careers-dialog-search-results',
    TEXT_FIELD_SEARCH: 'military-careers-text-field-search',
    TEXT_FIELD_SEARCH_CLEAR: 'military-careers-text-field-search-clear',
    TEXT_FIELD_SEARCH_DIALOG: 'military-careers-text-field-dialog-search',
  },
  NAVIGATION: {
    MY_LIBRARY: 'nav-item-my-library',
  },
  OPTIN: {
    BUTTON_ACCESS_WEB_APP: 'optin-button-access-web-app',
    BUTTON_GET_STARTED: 'optin-button-get-started',
    BUTTON_UPDATE_PROFILE: 'optin-button-update-profile',
    INPUT_ADDRESS: 'optin-input-address',
    INPUT_CITY: 'optin-input-city',
    INPUT_DATE_OF_BIRTH: 'optin-input-date-of-birth',
    INPUT_EMAIL: 'optin-input-email',
    INPUT_FIRST_NAME: 'optin-input-first-name',
    INPUT_GPA: 'optin-input-gpa',
    INPUT_HS_GRADUATION_YEAR: 'optin-input-hs-graduation-year',
    INPUT_LAST_NAME: 'optin-input-last-name',
    INPUT_LINE2: 'optin-input-line2',
    INPUT_MAJOR: 'optin-input-major',
    INPUT_PHONE: 'optin-input-phone',
    INPUT_SENSITIVE_OPT_IN: 'optin-input-sensitive-opt-in',
    INPUT_STATE: 'optin-input-state',
    INPUT_ZIP: 'optin-input-zip',
  },
  PAGINATION: {
    BACK_PAGE_BUTTON: 'pagination-back-page-button',
    FIRST_PAGE_BUTTON: 'pagination-first-page-button',
    LAST_PAGE_BUTTON: 'pagination-last-page-button',
    NEXT_PAGE_BUTTON: 'pagination-next-page-button',
    PAGE_ITEMS_COUNT: 'pagination-items-count',
    PAGE_SELECT: 'pagination-page-select',
    PAGE_SELECT_MENU_ITEM: 'pagination-select-menu-item',
  },
  PROFILE: {
    BUTTON_ABOUT_ME_TAB: 'profile-tabs-about-me',
    BUTTON_BACK_TO_PROFILE: 'profile-back-to-profile-button',
    BUTTON_CAREER_TAB: 'profile-tabs-career-button',
    BUTTON_CLOSE_EDIT_SCREEN_NAME_DIALOG: 'profile-close-edit-screen-name-dialog',
    BUTTON_COLLEGE_PREFERENCES_TAB: 'profile-tabs-college-preferences-button',
    BUTTON_EDIT_SCREEN_NAME: 'profile-edit-screen-name',
    BUTTON_EDIT_TEST_SCORE: (title: string) => `profile-edit-${title}-test-score-button`,
    BUTTON_HIGH_SCHOOL_TAB: 'profile-tabs-high-school-button',
    BUTTON_SAVE_SCREEN_NAME: 'profile-save-screen-name',
    BUTTON_SETTINGS_RN: 'profile-rn-settings-button',
    INPUT_EDIT_TEST_SCORE: (title: string) => `profile-edit-${title}-test-score-input`,
    TEXTFIELD_LEGAL_NAME: 'profile-close-edit-screen-name-dialog',
    TEXTFIELD_SCREEN_NAME: 'profile-close-edit-screen-name-dialog',
  },
  RESET_PASSWORD: {
    BUTTON_CANCEL: 'reset-password-cancel-button',
    BUTTON_SUBMIT: 'reset-password-submit-button',
    INPUT_NEW_PASSWORD: 'reset-password-new-input',
    INPUT_NEW_PASSWORD_CONFIRM: 'reset-password-confirm-input',
    INPUT_VERIFICATION_CODE: 'reset-password-verification-code-input',
  },
  SAVED_COLLEGES: {
    INFO_DIALOG: {
      CLOSE_BUTTON: 'saved-colleges-info-dialog-close-button',
      OPEN_DIALOG_BUTTON: 'open-saved-colleges-info-dialog-button',
    },
    SEARCH_DIALOG: {
      CLOSE_BUTTON: 'saved-colleges-search-dialog-close-button',
      OPEN_DIALOG_BUTTON: 'open-colleges-to-save-dialog-button',
      RESULT_COLLEGE_BUTTON: 'search-result-college-button',
      TEXTFIELD: 'colleges-to-save-search-dialog-textfield',
      TEXTFIELD_CLEAR_BUTTON: 'colleges-to-save-search-clear-textfield-button',
    },
    VIEW_MATCHED_COLLEGES_BUTTON: 'view-matched-colleges-button',
  },
  SCHOLARSHIPS: {
    BUTTON_BROWSE_ALL: 'scholarships-browse-all-button',
    BUTTON_BROWSE_BACK: 'scholarships-browse-back-button',
    BUTTON_EDIT_PREFERENCES: 'scholarships-edit-preferences-button',
    BUTTON_EXPLORE_TAB: 'scholarships-explore-tab-button',
    BUTTON_FILTER_APPLY: 'scholarships-filter-apply-button',
    BUTTON_FILTER_CANCEL: 'scholarships-filter-cancel-button',
    BUTTON_FILTER_RESET: 'scholarships-filter-reset-button',
    BUTTON_FILTER_STATUSES: 'scholarships-filter-statuses-button',
    BUTTON_FILTERS: 'scholarships-filters-button',
    BUTTON_HEART: (id: string): string => `scholarships-save-button-${id}`,
    BUTTON_MATCHED_TAB: 'scholarships-matched-tab-button',
    BUTTON_SAVE: 'scholarships-save-button',
    BUTTON_SAVED_TAB: 'scholarships-saved-tab-button',
    BUTTON_SEE_ALL_MATCHES: 'scholarships-see-all-matches',
    DETAILS: {
      BUTTON_APPLY: 'scholarships-details-apply-button',
    },
    FILTER: (id: string): string => `scholarships-filters-${id}`,
    LINK_DETAILS: (id: string): string => `scholarships-details-link-${id}`,
    SCHOLARSHIP_CARD: (id: string): string => `college-card-${id}`,
    TEXT_FIELD_SEARCH_SCHOLARSHIPS: 'scholarships-text-field-search-scholarships',
    TEXT_FIELD_SEARCH_SCHOLARSHIPS_CLEAR: 'scholarships-text-field-search-scholarships-clear',
    TEXT_FIELD_SEARCH_SCHOLARSHIPS_DIALOG: 'scholarships-text-field-dialog-search-scholarships',
  },
  SIGNIN: {
    BUTTON_CREATE_ACCOUNT: 'signin-create-account-button',
    BUTTON_SUBMIT: 'signin-submit-button',
    INPUT_EMAIL: 'signing-email-input',
    INPUT_PASSWORD: 'signing-password-input',
  },
  SIGNUP: {
    ACCOUNT_EXISTS_DIALOG: {
      BUTTON_CANCEL: 'signup-account-exists-cancel-button',
      BUTTON_SIGNIN: 'signup-account-exists-signin-button',
      DIALOG: 'signup-account-exists-dialog',
    },
    BUTTON_CANCEL: (id: string): string => `signup-cancel-button-${id}`,
    BUTTON_NEXT: (id: string): string => `signup-next-button-${id}`,
    BUTTON_OK: 'signup-ok-button',
    BUTTON_TOOLTIP_CLOSE: 'signup-tooltip-close-button',
    BUTTON_TOOLTIP_OPEN: 'signup-tooltip-open-button',
    EDUCATOR_CONNECTION_OPEN_BUTTON: 'signup-open-educator-connection-button',
    EDUCATOR_CONNECTION_REMOVE_BUTTON: 'signup-remove-educator-connection-button',
    GRID_CREATE_ACCOUNT: 'create-account',
    INPUT_ADDRESS_1: 'signup-address-1-input',
    INPUT_ADDRESS_2: 'signup-address-2-input',
    INPUT_ADDRESS_CITY: 'signup-address-city-input',
    INPUT_ADDRESS_COUNTRY: 'signup-address-country-input',
    INPUT_ADDRESS_POSTALCODE: 'signup-address-postalcode-input',
    INPUT_ADDRESS_STATE: 'signup-address-state-input',
    INPUT_DATE_OF_BIRTH: 'signup-date-birth-input',
    INPUT_EMAIL: 'signup-email-input',
    INPUT_FIRST_NAME: 'signup-first-name-input',
    INPUT_LAST_NAME: 'signup-last-name-input',
    INPUT_PASSWORD: 'signup-password-input',
    INPUT_PASSWORD_VERIFY: 'signup-password-verify-input',
    INPUT_TERMS_AGREE: 'signup-terms-agree-input',
    SURVEY_USER_DIALOG: {
      BUTTON_CANCEL: 'signup-survey-user-cancel-button',
      BUTTON_CONTINUE: 'signup-survey-user-continue-button',
      DIALOG: 'signup-survey-user-dialog',
    },
    UPDATE_INFO: {
      BUTTON_CANCEL: 'signup-update-info-cancel-button',
      BUTTON_EDIT: (key: string): string => `signup-update-info-edit-${key}-button`,
      BUTTON_UPDATE: 'signup-update-info-update-button',
    },
    USER_NOT_FOUND_DIALOG: {
      BUTTON_CLOSE: 'signup-user-not-found-close-button',
      BUTTON_CONTACT_SUPPORT: 'signup-user-not-found-contact-support-button',
      BUTTON_KEEP_GOING: 'signup-user-not-found-keep-going-button',
      BUTTON_SUBMIT: 'signup-user-not-found-submit-button',
      INPUT_EMAIL_ADDRESS: 'signup-user-not-found-email-input',
      INPUT_MESSAGE: 'signup-user-not-found-message-input',
    },
  },
  STEPPED_FORM: {
    BUTTON_BACK_CANCEL: (id: string): string => `stepped-form-back-cancel-button-${id}`,
    BUTTON_NEXT_SUBMIT: (id: string): string => `stepped-form-next-submit-button-${id}`,
  },
  SURVEY: {
    BUTTON_BACK: (id: string): string => `survey-back-button-${id}`,
    BUTTON_CLOSE: 'survey-close-button',
    BUTTON_NEXT: (id: string): string => `survey-next-button-${id}`,
    BUTTON_TOOLTIP_CLOSE: 'survey-tooltip-close-button',
    BUTTON_TOOLTIP_OPEN: 'survey-tooltip-open-button',
    CHECKBOX_QUESTION_ELEMENT: 'survey-question-checkbox',
    INPUT_COLLECTION_SEARCH: 'survey-collection-search-input',
    INPUT_HIGHSCHOOL_SEARCH: 'survey-highschool-search-input',
    MAJORS: {
      BUTTON_SELECT_MAJORS: 'survey-majors-select-majors-button',
      DRAWER: {
        BUTTON_CANCEL: 'survey-majors-drawer-cancel-button',
        BUTTON_CLEAR_SEARCH: 'survey-majors-drawer-search-clear-button',
        BUTTON_CLOSE: 'survey-majors-drawer-close-button',
        BUTTON_SELECT: 'survey-majors-drawer-select-button',
        CHECKBOX_MAJOR: (id: string): string => `survey-majors-drawer-${id}-checkbox`,
        INPUT_SEARCH: 'survey-majors-drawer-search-input',
      },
    },
    RADIO_QUESTION_ELEMENT: 'survey-question-radio',
  },
  VERIFY: {
    BUTTON_RESEND: 'verify-resend-button',
    BUTTON_SUBMIT: 'verify-submit-button',
    INPUT_VERIFICATION_CODE: 'verify-verification-code-input',
  },
};

export default TAG_IDS;
